<template>
    <div class="qunian">
        <div class="banner">
            <img src="https://2lc-oss.erlangcha.com/indeximg/searchWord1.png" alt="">
            <!-- <div class="time">去年{{dateFtt("MM-dd",new Date(time))}}</div> -->
        </div>
        <div class="main">
            <div class="dan" v-for="(item,key) in ShopHistoryList" :key="key">
                <!-- <div class="image"><img :src="item.shop_img" alt=""></div> -->
                <div class="text">
                    <div class="title">{{item.param.word}}</div>
                    <!-- <div class="tag">
                        <p>{{item.price == item.price_max ? '￥' + item.price : item.price + '~' + item.price_max}}</p>
                        <p>销量：{{item.today_volume}}</p>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="backimg">
            <div class="shuiyin">
                <div class="dan" v-for="(item,key) in 16" :key="key"><img src="https://2lc-oss.erlangcha.com/indeximg/shuiyinadmin.png" alt=""></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            ShopHistoryList:[],
            time:'',
        };
    },
    mounted() {
        if (this.$route.query.time) {
            this.time = this.$route.query.time;
        } else {
            this.time = this.getDay()
        }
        this.ongetErShopHistoryList()
    },

    methods: {
        // 获取上一年
        getDay() {
            var today = new Date();
            var targetday_milliseconds =
                today.getTime() + 1000 * 60 * 60 * 24 * (-1);
            today.setTime(targetday_milliseconds);
            var tYear = today.getFullYear();
            var tMonth = today.getMonth();
            var tDate = today.getDate();
            tMonth = this.doHandleMonth(tMonth + 1);
            tDate = this.doHandleMonth(tDate);
            return tYear + "-" + tMonth + "-" + tDate;
        },
        doHandleMonth(month) {
            var m = month;
            if (month.toString().length == 1) {
                m = "0" + month;
            }
            return m;
        },
        ongetErShopHistoryList(){
            var param = {
                date:this.time,
                uri:'api/red_blue_sea/keywordRelated',
            }
            this.$service.get(this.$api.weblogSearch, param, (res)=> {
                if(res.code == '200'){
                    res.data.forEach((v,k) => {
                        v.param = JSON.parse(v.params)
                    });
                    console.log(res.data)
                    this.ShopHistoryList = res.data
                }
            })
        },
    },


    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
.qunian{
    width: 1360px;
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    .banner{
        .time{
            position: absolute;
            top: 160px;
            left: 430px;
            font-size: 140px;
            color: #2aade5;
            font-weight: 800;
        }
    }
    .main{
        background: url(https://2lc-oss.erlangcha.com/indeximg/qunianxl2.jpg) center top;
        padding: 30px;
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        // margin-top: -96px;
        .dan{
            // width: 630px;
            // height: 200px;
            background: #fff;
            box-shadow: 15px 15px 8px 4px #48a1d1;
            border-radius: 30px;
            display: flex;
            justify-content: space-between;
            padding: 20px;
            margin-bottom: 30px;
            .image{
                width: 120px;
                height: 120px;
                border-radius: 5px;
                border: 2px solid #48a1d1;
                box-shadow: 9px 9px 1px -2px #aee7ff;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .text{
                // width: 440px;
                text-align: left;
                position: relative;
                .title{
                    font-size: 28px;
                    color: #000;
                    font-weight: 500;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }
                .tag{
                    margin-top: 20px;
                    display: flex;
                    justify-content: space-between;
                    position: absolute;
                    bottom: 0;
                    width: 100%;
                    p{
                        font-size: 28px;
                    }
                }
            }
        }
    }
    .backimg {
            position: absolute;
            top: 0;
            left: 70px;
            display: flex;
            flex-wrap: wrap;
            text-align: left;
            width: 1550px;
            height: 600px;
            .shuiyin{
                width: 1100px;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                position: absolute;
                z-index: 100;
                left: 100px;
                .dan{
                    margin: 0 150px;
                    margin-bottom: 300px;
                    opacity: .2;
                    transform:rotate(30deg);
                }
            }
        }
}
</style>
<style lang="scss">
</style>